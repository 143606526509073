import * as THREE from 'three';
import { Text } from 'troika-three-text'
import * as materials from './materials';
import { gsap } from "gsap";

let baseUnit = 3.92 / 2 // 1 meter
const xTextValue = new Text()
const yTextValue = new Text()
let xLine
let yLine

function getMeasurements(scene, method, center, wallsLength, xMin, xMax, clipPosX) {

    console.log(center, wallsLength, xMin, xMax, clipPosX)

    center = (xMin + clipPosX) / 2
    let worldLength = Math.abs(xMin) + clipPosX
    let realLength = wallsLength.replace(/\D/g, "");


    const xLineGeometry = new THREE.BoxGeometry(1, 0.01, 0.01);
    xLine = new THREE.Mesh(xLineGeometry, materials.metalMat);
    xLine.position.set(center, 2.1275, .125)
    xLine.scale.x = worldLength
    xLine.castShadow = true;
    xLine.receiveShadow = true;
    scene.add(xLine);

    const yLineGeometry = new THREE.BoxGeometry(0.01, 1, 0.01);
    yLine = new THREE.Mesh(yLineGeometry, materials.metalMat);
    yLine.position.set(-2.1025, 0.17, .125)
    yLine.scale.y = baseUnit * 1.845
    yLine.castShadow = true;
    yLine.receiveShadow = true;
    scene.add(yLine);

    // X text value
    scene.add(xTextValue)
    xTextValue.text = ''
    xTextValue.fontSize = 0.075
    xTextValue.fontWeight = 'bold'
    xTextValue.position.set(center - 0.1, 2.24, .2)
    xTextValue.color = 0x222222

    // Y text value
    scene.add(yTextValue)
    yTextValue.text = ''
    yTextValue.fontSize = 0.075
    yTextValue.fontWeight = 'bold'
    yTextValue.position.set(-2.45, 2, .125)
    yTextValue.color = 0x222222

    gsap.fromTo(xLine.scale,
        {
            x: 0
        },
        {
            x: worldLength,
            duration: 2
        })

    gsap.fromTo(yLine.scale, {
        y: 0
    },
        {
            y: baseUnit * 1.845,
            duration: 2
        })

    gsap.fromTo(xTextValue,
        {
            text: 0,
            fillOpacity: 0
        }, {
        text: realLength,
        fillOpacity: 1,
        duration: 2,
        onUpdate: function () {
            xTextValue.text = Math.floor(xTextValue.text) + ' cm'
        }
    })

    gsap.fromTo(yTextValue,
        {
            text: 0,
            fillOpacity: 0
        }, {
        text: 186,
        fillOpacity: 1,
        duration: 2,
        onUpdate: function () {
            yTextValue.text = Math.floor(yTextValue.text) + ' cm'
        }
    })

}

function updateMeasurements(wallsLength, clipPosX) {
    let realLength = wallsLength.replace(/\D/g, "");
    xTextValue.text = realLength + ' cm'

    let worldLength = 1.96 + clipPosX
    xLine.scale.x = worldLength

    let center = (-1.96 + clipPosX) / 2
    xTextValue.position.set(center - 0.1, 2.24, .2)
    xLine.position.set(center, 2.1275, .125)
}

function resetMeasurements() {

    gsap.to(xLine.scale,
        {
            x: 0,
            duration: 2
        }
    )

    gsap.fromTo(yLine.scale,
        {
            y: baseUnit * 1.845
        },
        {
            y: 0,
            duration: 2
        }
    )

    gsap.to(xTextValue,
        {
            text: 0,
            fillOpacity: 0,
            duration: 2,
            onUpdate: function () {
                xTextValue.text = Math.floor(xTextValue.text) + ' cm'
            }
        })

    gsap.fromTo(yTextValue,
        {
            text: 186,
            fillOpacity: 1
        }, {
        text: 0,
        fillOpacity: 0,
        duration: 2,
        onUpdate: function () {
            yTextValue.text = Math.floor(yTextValue.text) + ' cm'
        }
    })
}

export { getMeasurements, resetMeasurements, updateMeasurements, xTextValue, yTextValue, baseUnit }