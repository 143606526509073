// accordition
let activeObjectList, objectList

$(document).on('click', '.category_label', function (e) {

    activeObjectList = document.querySelectorAll('.active_category > .objects')
    if (activeObjectList.length > 0) {
        activeObjectList[0].style.maxHeight = "0";
    }

    if ($(this).parent('.active_category').length) {
        $(this).parent('.active_category').removeClass('active_category')
    } else {
        objectList = $(this).parent('.category').children('.objects')[0]
        objectList.style.maxHeight = objectList.scrollHeight + "px";
        $('.category').removeClass('active_category')
        $(this).parent('.category').addClass('active_category')
    }
})



